<template>
  <v-row justify="center">
    <v-dialog :value="true" persistent max-width="600">
      <v-card class="pa-8">
        <v-row class="justify-space-between">
          <v-icon large color="error" v-text="'highlight_off'" />
          <v-btn
            icon color="primary"
            @click="$emit('close-dialog-open-competition-missing-data')"
          >
            <v-icon v-text="'close'" />
          </v-btn>
        </v-row>
        <v-row>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="pl-0 mb-8">
                <span class="mb-8" v-html="$t('project.requirementStepsForOpenCompetition.title')" />
              </v-col>
            </v-row>
            <v-row v-for="(requirement, i) in requirementStepsForOpenCompetition" :key="i" align="start" class="my-2">
              <v-col cols="1">
                <v-icon color="primary" small v-text="requirement.icon" />
              </v-col>
              <v-col cols="11">
                <span v-html="$t(`project.requirementStepsForOpenCompetition.${requirement.data}`)" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>
        <v-card-actions class="justify-space-around align-center my-4">
          <v-btn
            color="primary" dark min-width="12rem" elevation="5"
            @click="() => {
              $emit('close-dialog-open-competition-missing-data')
              $router.push(firstRequirement.route)
            }"
            v-text="$t('common.accept') "
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OpenCompetitionMissingDataDialog',
  computed: {
    ...mapGetters('project', ['requirementStepsForOpenCompetition']),
    firstRequirement: ({ requirementStepsForOpenCompetition }) => requirementStepsForOpenCompetition[0],
  },
}
</script>
